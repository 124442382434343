<template>
  <div>
    <v-btn
      v-if="card"
      elevation="0"
      icon
      @click="
        openForm();
        fetchAll();
      "
      style="position: absolute; top: 3px; right: 12px"
    >
      <v-icon size="36">mdi-plus-circle</v-icon>
    </v-btn>
    <v-btn
      v-else
      elevation="0"
      icon
      @click="
        openForm();
        fetchAll();
      "
      style="height: 30px; margin-top: 5px"
    >
      <v-icon size="36">mdi-plus-circle</v-icon>
    </v-btn>
    <v-dialog width=" 500" v-model="dialog">
      <v-card id="formMove">
        <v-card-title class="headline">
          <span v-if="title == 'general'">
            {{ $t("inventorys.new_movement") }}
          </span>
          <span v-else-if="title == 'entradas'">
            {{ $t("inventorys.new_receipt") }}
          </span>
          <span v-else-if="title == 'salidas'">
            {{ $t("inventorys.new_issue") }}
          </span>
          <span v-else-if="title == 'appointment'">
            {{ $t("inventorys.new_movement_appointment") }}
          </span>
        </v-card-title>
        <div class="close">
          <v-btn icon @click="dialog = false" small>
            <v-icon id="iconClose">mdi-close</v-icon>
          </v-btn>
        </div>

        <v-card-text>
          <movement-form
            ref="form"
            @cancel="dialog = false"
            :items="items"
            :title="title"
            :action="action"
            @refreshTable="
              $emit('update');
              dialog = false;
            "
            :inventorymovement="inventorymovement"
          ></movement-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  name: "FormMove",
  props: ["title", "card", "appointment_id"],
  components: {
    MovementForm: () => import("@/components/inventorymovement/Form"),
  },
  data() {
    return {
      items: [],
      action: "save",
      dialog: false,
      inventorymovement: { description: null, product_cody: null },
    };
  },
  mounted() {
    this.fetchAll();
  },
  methods: {
    ...mapActions("inventory", ["getAll"]),

    fetchAll() {
      this.getAll().then((data) => {
        //console.log(data);
        this.items = data.data;
      });
    },
    openForm() {
      this.inventorymovement.description = null;
      this.inventorymovement.pay_mode = null;
      this.inventorymovement.type_product = null;
      this.inventorymovement.product_cody = null;

      this.inventorymovement.cantidad = null;
      this.inventorymovement.invoice_number = null;
      this.inventorymovement.data = null;
      this.inventorymovement.price = null;
      this.inventorymovement.appointment_id = this.appointment_id;
      setTimeout(() => {
        this.$refs.form.inventory = {};
      }, 500);
      console.log(this.inventorymovement);
      this.actions = "edit";
      this.dialog = true;
    },
  },
};
</script>
<style lang="sass">
#formMove
  .headline
    span
      font-size: 24px !important
      font-weight: 500 !important
      line-height: 2rem
      letter-spacing: normal !important
      font-family: "Roboto", sans-serif !important
  .close .v-btn
    top: 0
    right: 0px
    position: absolute
    .v-icon
      color: var(--v-primary-base)
      position: absolute
</style>
